<template>
  <!-- <el-button @click="openDialog" size="small" type="primary">{{name}}</el-button> -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" title="添加人员" class="clearfix button_bg" >
    <div style="margin-bottom: 10px;">
        <el-input placeholder="姓名/身份证号"  @keyup.enter.native="getList()" v-model="salaryName" style="width: 260px;" class="filter-item" size="small" />
        <el-button class="filter-item" @click = getList() type="primary" size="small"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
      </div>
      <el-table v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;height:320px;overflow:auto"  @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" align="center" width="40" />
        <el-table-column label="序号" prop="id" align="center" width="45" type="index"></el-table-column>
        <el-table-column label="姓名" align="center" min-width="90">
            <template #default="{row}">
              <span>{{row.name}}</span>
            </template>
        </el-table-column>
        <el-table-column label="证件类型" align="center" min-width="100">
          <template #default="{row}">
            <span>{{row.iDType}}</span>
          </template>
        </el-table-column>
        <el-table-column label="证件号码" align="center" min-width="100">
          <template #default="{row}">
            <span>{{row.iDNo}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="savePersonal()" size="small">确定</el-button>
      </div>
    </el-dialog>
</template>

<script>
import { salaryEmpList,saveEaSalary,saveEaSalaryRemuneration,saveEaSalaryAddGr,saveEaSalaryOnly } from "@/api/salary.js"
export default {
  name: 'addNormal',
  
  props: {
    type: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      list: [],
      saveall:[],
      listLoading: false,//表格遮罩层
      salaryName:'',//搜索条件
      listQuery: {
        page: 1,
        limit: 20,
        sheetId: this.$store.getters["commons/params"].sheetId
      },
      number: 0,
    }
  },
  created() {
    // 获取表格高度
    // this.contentStyleObj=this.$getHeight(210)
    // window.addEventListener('resize', this.getHeight);
  },
  methods: {
    // init(sheetId){
    //   this.listQuery.sheetId = sheetId
    //   this.getList()
    // },
    handleSelectionChange(val) {
      let arr = [];
      if(this.type == '正常工资薪资') {
        this.number = this.$store.getters["commons/params"].salaryNormalId
      }else if(this.type == '劳务报酬所得') {
        this.number = this.$store.getters["commons/params"].salaryServicesId
      }else if(this.type == '全年一次性奖金') {
        this.number = this.$store.getters["commons/params"].salaryBonusId
      }else if(this.type == '个人生产经营所得') {
        this.number = this.$store.getters["commons/params"].salaryPersonId
      }else{
        this.number = this.$store.getters["commons/params"].salaryPersonHdzsId
      }
      val.map(item => {
        arr.push({ sheetId: this.number, employeeId: item.id })
      })
      this.saveall = arr;
      //console.log(this.saveall,'this.saveall')
    },
    openDialog(){
      this.getList()
      // if(row){ this.info = row }
      this.dialogFormVisible = true
    },
    savePersonal() {
      if (this.saveall.length == 0) {
        this.$message({
          type: "info",
          message: "请选择人员"
        });
        return false;
      }
      if(this.type == '正常工资薪资'){
        let param = {
        list:this.saveall,
        period:this.$store.getters['user/comInfo'].period,
        comId:this.$store.getters['user/comInfo'].comId*1
      }
        saveEaSalary(param).then(res => {
          this.dialogFormVisible = false;
          if(res.data.msg == 'success') {
            this.$message({
              type: "success",
              message: "添加成功"
            });
            this.$emit("success")
          }
          
        });
      }else if(this.type == '劳务报酬所得'){
        saveEaSalaryRemuneration(this.saveall).then(res => {
          this.dialogFormVisible = false;
          if(res.data.msg == 'success') {
            this.$message({
              type: "success",
              message: "添加成功"
            });
            this.$emit("success")
          }
          
        });
      }else if(this.type == '全年一次性奖金'){
        saveEaSalaryOnly(this.saveall).then(res => {
          this.dialogFormVisible = false;
          if(res.data.msg == 'success') {
            this.$message({
              type: "success",
              message: "添加成功"
            });
            this.$emit("success")
          }
          
        });
      }else{
        saveEaSalaryAddGr(this.saveall).then(res => {
          this.dialogFormVisible = false;
          if(res.data.msg == 'success') {
            this.$message({
              type: "success",
              message: "添加成功"
            });
            this.$emit("success")
          }
        });
      }
    },
    getList() {
      this.listLoading=true
      if(this.type == '正常工资薪资') {
        salaryEmpList({sheetId: this.$store.getters["commons/params"].salaryNormalId,salaryName:this.salaryName}).then(res => {
          if (res.data.data.list) {
            this.list = res.data.data.list
          } else {
            this.list = []
          }
          this.listLoading=false
        })
      }else if(this.type == '劳务报酬所得') {
        salaryEmpList({sheetId: this.$store.getters["commons/params"].salaryServicesId,salaryName:this.salaryName}).then(res => {
          if (res.data.data.list) {
            this.list = res.data.data.list
          } else {
            this.list = []
          }
          this.listLoading=false
        })
      }else if(this.type == '全年一次性奖金') {
        salaryEmpList({sheetId: this.$store.getters["commons/params"].salaryBonusId,salaryName:this.salaryName}).then(res => {
          if (res.data.data.list) {
            this.list = res.data.data.list
          } else {
            this.list = []
          }
          this.listLoading=false
        })
      }else if(this.type == '个人生产经营所得'){
        salaryEmpList({sheetId: this.$store.getters["commons/params"].salaryPersonId,salaryName:this.salaryName}).then(res => {
          if (res.data.data.list) {
            this.list = res.data.data.list
          } else {
            this.list = []
          }
          this.listLoading=false
        })
      }else{
        salaryEmpList({sheetId: this.$store.getters["commons/params"].salaryPersonHdzsId,salaryName:this.salaryName}).then(res => {
          if (res.data.data.list) {
            this.list = res.data.data.list
          } else {
            this.list = []
          }
          this.listLoading=false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 
.dialog-footer {
  margin-top: 15px;
}
</style>